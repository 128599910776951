'use client';

import { usePathname } from 'next/navigation';

import { Error as ErrorNotice } from '@/components';

type ErrorProps = {
  error: Error & Partial<Record<'digest', string>>;
  reset: () => void;
};

export default function RootError({ error, reset }: ErrorProps) {
  const pathname = usePathname();

  const isProduction =
    process.env.NODE_ENV === 'production' || pathname.startsWith('/_next');

  return (
    <ErrorNotice
      heading={!isProduction ? error.message : undefined}
      resetAction={reset}
    />
  );
}
